var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"catalog","fluid":"","tag":"section"}},[_c('base-v-component',{attrs:{"heading":"Catalogue de cours"}}),_c('v-card',{staticClass:"px-5 py-3"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Rechercher un cours","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"py-3"}),_c('v-data-table',{attrs:{"id":"table-catalogue","headers":_vm.headers,"items":_vm.courses,"search":_vm.search,"group-by":"theme.name","items-per-page":15},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price)+" €")])]}},{key:"item.priceIntra",fn:function(ref){
var item = ref.item;
return [(item.priceIntra)?_c('span',[_vm._v(_vm._s(item.priceIntra)+" €")]):_vm._e()]}},{key:"item.nbDays",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nbDays)+" jour"+_vm._s(item.nbDays > 1 ? 's' : ''))])]}},{key:"item.nbHours",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nbHours)+" heure"+_vm._s(item.nbHours > 1 ? 's' : ''))])]}},{key:"item.operations",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'ModifierCours', params: { id: item.id } })}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.dialogDeleteRef.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"py-1"}),_c('div',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{attrs:{"color":"primary","x-large":""},on:{"click":function($event){return _vm.$refs.createThemeRef.show()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_vm._v(" Créer un nouveau thème ")],1),_c('v-btn',{attrs:{"color":"primary","x-large":"","to":{ name: 'CreerCours'}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_vm._v(" Créer un nouveau cours ")],1)],1),_c('dialog-delete',{ref:"dialogDeleteRef",on:{"courseDeleted":_vm.spliceCoursesList}}),_c('create-theme',{ref:"createThemeRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }