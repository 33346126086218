<template>
  <v-container
    id="catalog"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Catalogue de cours"
    />
    <v-card
      class="px-5 py-3"
    >
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher un cours"
        single-line
        hide-details
      />

      <div class="py-3" />

      <v-data-table
        id="table-catalogue"
        :headers="headers"
        :items="courses"
        :search="search"
        group-by="theme.name"
        :items-per-page="15"
        @click:row="rowClick"
      >
        <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
          <td
            :colspan="headers.length"
          >
            <v-btn
              @click="toggle"
              x-small
              icon
              :ref="group"
            >
              <v-icon v-if="isOpen">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
            <span class="mx-5 font-weight-bold">{{ group }}</span>
          </td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <b>{{ item.name }}</b>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span>{{ item.price }} €</span>
        </template>
        <template v-slot:[`item.priceIntra`]="{ item }">
          <span v-if="item.priceIntra">{{ item.priceIntra }} €</span>
        </template>
        <template v-slot:[`item.nbDays`]="{ item }">
          <span>{{ item.nbDays }} jour{{item.nbDays > 1 ? 's' : '' }}</span>
        </template>
        <template v-slot:[`item.nbHours`]="{ item }">
          <span>{{ item.nbHours }} heure{{item.nbHours > 1 ? 's' : '' }}</span>
        </template>
        <template v-slot:[`item.operations`]="{ item }">
          <v-btn
            class="mx-1"
            small
            color="blue"
            @click.stop="$router.push({ name: 'ModifierCours', params: { id: item.id } })"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            class="mx-1"
            small
            color="red"
            @click.stop="$refs.dialogDeleteRef.deleteItem(item)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <div class="py-1" />

    <div
      class="d-flex justify-end mb-3"
    >
      <v-btn
        color="primary"
        x-large
        @click="$refs.createThemeRef.show()"
      >
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        Créer un nouveau thème
      </v-btn>
      <v-btn
        color="primary"
        x-large
        :to="{ name: 'CreerCours'}"
      >
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        Créer un nouveau cours
      </v-btn>
    </div>
    <dialog-delete
      ref="dialogDeleteRef"
      @courseDeleted="spliceCoursesList"
    />
    <create-theme
      ref="createThemeRef"
    />
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    name: 'Catalog',

    components: {
      DialogDelete: () => import('../../component/DialogDelete'),
      CreateTheme: () => import('../../component/CreateTheme'),
    },

    data() {
      return {
        search: '',
        headers: [
          {
            sortable: true,
            text: 'Réf.',
            value: 'reference',
          },
          {
            sortable: true,
            text: 'Intitulé',
            value: 'name',
          },
          {
            sortable: true,
            text: 'Durée jours',
            value: 'nbDays',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Durée heures',
            value: 'nbHours',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Prix INTER',
            value: 'price',
            align: 'right',
          },
          {
            sortable: true,
            text: 'Prix INTRA',
            value: 'priceIntra',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Opérations',
            value: 'operations',
          },
        ],
        courses: [],
      }
    },
    created () {
      this.axios.get('/cours')
        .then((res) => {
          this.courses = res.data;
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du catalogue', type: 'warn' });
        });
    },

    methods: {

      spliceCoursesList (item) {
        this.courses.splice(this.courses.indexOf(item), 1)
      },

      rowClick(item, row) {
        this.$router.push({ name: 'CoursDetail', params: { id: item.id } });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>

#table-catalogue >>> th:last-child {
  width: 140px;
  text-align: center !important;
}

#table-catalogue >>> tbody tr:not(.v-row-group__header) {
  cursor: pointer;
  transition: background-color 0.1s;
}

#table-catalogue >>> tbody tr:not(.v-row-group__header):hover {
  background-color: #f8f8f8;
  font-weight: 400 !important;
}

</style>
